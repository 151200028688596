<template>
  <v-container fluid class="pt-0 fill-height">
    <MainModal
        :main="{ component: 'FlatCounterAvg', title: 'Покази по середньому' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_counter_avg)"
        :modal="show_counter_avg_dialog"
        @updateItemModal="counterAvgUpdateItemModal"
    />
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-col cols="12" style="flex: 0">
        <v-card>
          <v-toolbar tile elevation="0" outlined dense style="border: none"
                     :height="$vuetify.breakpoint.xs ? 90 : 54">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">
                Реєстр середніх показів за  {{ current_month | formatDate('MMMM YYYY') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="shrink">
                <v-row class="align-center">
                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"
                                  @onMonthChange="onMonthChange"/>
                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openCounterAvgCreateDialog"
                             v-bind="attrs"
                             v-on="on"
                             class="grey lighten-4 mr-1">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Створити покази по середньому</span>
                  </v-tooltip>
                </v-row>
              </v-toolbar-items>
            </template>
            <template v-else>
              <div style="width: 100%">
                <div class="d-flex mb-2">
                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                    Реєстр середніх показів за  {{ current_month | formatDate('MMMM YYYY') }} року
                  </v-toolbar-title>
                </div>
                <div class="d-flex">
                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"
                                  @onMonthChange="onMonthChange"/>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openCounterAvgCreateDialog"
                             v-bind="attrs"
                             v-on="on"
                             class="grey lighten-4 mr-1">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Створити покази по середньому</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <template v-if="items.length">
          <v-card
              v-for="(item, idx) in items" :key="idx" tile elevation="2"
              class="mb-2 d-flex flex-wrap flex-row row-card"
              style="border-left: 3px solid #5bb55f"
              @click.stop="onCounterAvgItemClick(item)"
          >
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
              <div>Дата створення</div>
              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
            </v-col>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Послуга</div>
              <div>
                <v-icon size="22"
                        :color="getAccountIcon(item.icon, 'color')"
                        class="mr-2">
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
                {{ item.service_name }}
              </div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Об’єм / К-сть</div>
              <div>
                <span>{{ item.reading_result | formatToFixed }}</span> / <span>{{ item.reading_count }}</span></div>
            </v-col>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''">
              <div>Відбір</div>
              <div v-html="item.filter_represent"></div>
            </v-col>
          </v-card>
        </template>
        <template v-else>
          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
            <div class="wrapper align-center text-center">
              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
              <div class="title grey--text text--darken-2 px-4">Дані для відображення відсутні <br> Спершу створіть список із середніми показами</div>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_CURRENT_MONTH} from "@/store/actions/work_month";
import {getAccountIcon, getCurrentDate} from "@/utils/icons";
import {GET_ALL_FLAT_COUNTER_AVG} from "@/store/actions/flat_counter_avg";

export default {
  name: "FlatCounterAvgList",
  components: {
    MonthComponent: () => import("@/components/MonthComponent"),
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      current_month_: 'getCurrentMonth',
      current_month_legacy_: 'getCurrentMonthLegacy',
      items: 'getFlatCountersAvg',
      legacy: 'isLegacy'
    })
  },
  data() {
    return {
      current_month: null,
      selected_counter_avg: {},
      show_counter_avg_dialog: false
    }
  },
  methods: {
    ...mapActions(
        {
          fetchHeaders: GET_ALL_FLAT_COUNTER_AVG
        }
    ),
    getCurrentDate,
    getAccountIcon,
    onMonthChange(payload) {
      const local_payload = {
        month: payload,
        legacy: this.legacy
      }
      this.fetchHeaders(local_payload)
    },
    counterAvgUpdateItemModal() {
      this.show_counter_avg_dialog = false
      this.selected_counter_avg = {}
    },
    openCounterAvgCreateDialog() {
      this.selected_counter_avg = {
        month: this.legacy ? this.current_month_legacy_ : this.current_month_,
        create_date: this.getCurrentDate(),
        legacy: this.legacy
      }
      this.show_counter_avg_dialog = true
    },
    onCounterAvgItemClick(payload) {
      this.selected_counter_avg = JSON.parse(JSON.stringify(payload))
      this.show_counter_avg_dialog = true
    },
  },
  created() {
    this.$store.dispatch(GET_CURRENT_MONTH)
      .then(finish => {
        if (finish) {
          this.current_month = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
          const payload = {
            month: this.current_month,
            legacy: this.legacy
          }
          this.fetchHeaders(payload)
        }
      })
  }
}
</script>

<style scoped lang="scss">
  .row-card {
    div > div:nth-child(1) {
      font-size: .68rem;
      font-weight: 400;
      color: #757575
    }
    div > div:nth-child(2) {
      font-size: .84rem;
      color: #4a4a4a;

      span:nth-child(1) {
        font-weight: 500;
        color: #2a9d2f;
      }
      span:nth-child(2) {
        font-weight: 500;
        color: #2d2d2d;
      }
    }
  }
</style>